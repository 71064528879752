.container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  // PC
  @media (min-width: 1036px) {
    padding: 40px;
  }
  // SP
  @media screen and (max-width: 1035px) {
    padding: 40px 28px;
  }
}

.linksContainer {
  // PC
  @media (min-width: 1036px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
  }
  // SP
  @media screen and (max-width: 1035px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
