.section {
  border-radius: 4px;
  padding: 20px 28px;
}

@media screen and (max-width: 1036px) {
  .section {
    border-radius: 0;
  }
}

.customer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.flex {
  display: flex;
  align-items: center;
}

.flexRight {
  margin-left: auto !important;
}

.row {
  display: table;
  width: 100%;
}

.column {
  display: table-cell;
  vertical-align: top;
  width: 80px;
}

.columnLast {
  display: table-cell;
  vertical-align: top;
  width: calc(100% - 80px);
}

.historyLink {
  display: block;
  margin: 20px -28px 0 -28px;
  padding: 20px 28px 0;
  width: calc(100% + 56px);
  border-top: 1px solid #c8c8c8;
}

.angle {
  float: right;
  margin-top: 5px;
}

.wordBreak {
  word-break: break-all;
}
