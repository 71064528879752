.section {
  border-radius: 4px;
  padding: 20px 28px;
}

@media screen and (max-width: 1036px) {
  .section {
    border-radius: 0;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.newsListItem {
  border-bottom: 1px solid #c5c5c5;
}

.news {
  display: block;
  padding: 20px 25px 20px 0;
  transition: 0.3s ease all;
  position: relative;
}

.angle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 1em;
  height: 1em;
  display: block;
}

.news:hover {
  opacity: 0.6;
}

.tag {
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
  margin-right: 5px;
  margin-left: 5px;
}

.tag:first-child {
  margin-left: 0;
}

.yellow {
  color: #5a5a5a;
  background-color: #fac83c;
}

.link {
  font-size: 12px;
  border-bottom: 1px solid;
  margin-left: auto;
}
