.root {
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  margin-bottom: 20px;
  @media screen and (min-width: 1036px) {
    flex-direction: row;
    align-items: center;
  }
  @media screen and (max-width: 1035px) {
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    margin-top: 20px;
  }

  &.isRootPage {
    display: none;
  }
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
}

.backButton {
  margin-right: 14px;
  @media screen and (max-width: 1035px) {
    margin-bottom: 10px;
  }
}

.buttonStartIcon {
  margin-right: 8px;
}
