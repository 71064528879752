.container {
  max-width: 400px;
  @media screen and (max-width: 768px) {
    width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 40px);
    max-height: calc(-webkit-fill-available - 40px); // 古いiOS用
    max-height: calc(100dvh - 40px); // 新しいiOS用
  }
}
.fullWidth {
  flex: 1;
}

// デフォルトの400pxの高さを強制解除
.modalBody {
  min-height: auto !important;
}
