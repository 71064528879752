.container {
  position: relative;
}

.shineFirst {
  left: 12%;
  top: 40%;
}

.mileProgramDashboard {
  position: relative;
}

.mileProgramDashboardShine {
  display: none;
}

@media screen and (max-width: 1036px) {
  .mileProgramDashboardShine {
    display: inline-block;

    &:first-child {
      position: absolute;
      left: 16%;
      top: 27%;

      &.shine__gold {
        left: 12%;
        top: 40%;
      }
    }

    &:nth-child(2) {
      position: absolute;
      right: 11%;
      bottom: 9%;
    }
  }
}
