.customer {
  padding: 10px 28px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1036px) {
  .customer {
    border-radius: 0;
  }
}

.button {
  display: inline-block;
  transition: 0.3s ease all;
}

.button:hover {
  opacity: 0.6;
}

.underline {
  border-bottom: 1px solid;
}
