.root {
  display: flex;
  padding: 40px;
  align-items: center;
  justify-content: center;
}

.specialPadding {
  padding-bottom: 110px !important;
}

.backButton {
  margin-right: 14px;
  @media screen and (max-width: 1035px) {
    margin-bottom: 10px;
  }
}

.buttonStartIcon {
  margin-right: 8px;
}
