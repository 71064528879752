.container {
  position: relative;
}

.subscriptionAdd {
  display: block;
  color: #5a5a5a;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #fff;
  padding: 18px 20px;
  position: relative;

  &.disabled {
    opacity: 0.6;
    border: none;
  }
}

.freeze {
  &:after {
    display: none;
  }
}

.subscriptionTag {
  vertical-align: bottom !important;
  margin-left: 0 !important;
  margin-right: 10px !important;
}

.angle {
  float: right;
  margin-top: 5px;
}

.restart {
  padding: 5px 12px;
  color: #fff;
  border-radius: 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: -20px;
  z-index: 10;
  width: fit-content;
  margin: auto;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #ff6478 transparent transparent transparent;
    display: block;
    position: absolute;
    bottom: -9px;
    left: 0;
    right: 0;
    margin: auto;
  }

  &:hover {
    opacity: 0.6;
  }
}

.restartAngle {
  margin-left: 5px;
}

.disabled {
  opacity: 0.6;
  background-color: #f0f0f0 !important;
  cursor: not-allowed;
}
