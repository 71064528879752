// ランク別のカラー表示のスタイル定義
$economy-primary: #78b428;
$economy-primary-bg: #e4f0d4;
$economy-secondary: #ffffff;
$economy-secondary-bg: #78b428;

$business-primary: #00508c;
$business-primary-bg: #e5edf3;
$business-secondary: #ffffff;
$business-secondary-bg: #00508c;

$first-primary: #b93c0f;
$first-primary-bg: #f1d8cf;
$first-secondary: #ffffff;
$first-secondary-bg: #b93c0f;

$vip-primary: #b9910f;
$vip-primary-bg: #fef4d8;
$vip-secondary: #ffffff;
$vip-secondary-bg: #b9910f;
