.root {
  // SP用スタイル
  @media (max-width: 1035px) {
    padding: 0 20px 20px;
  }
  // PC用スタイル
  @media (min-width: 1036px) {
    padding: 0 0 20px;
  }
}
