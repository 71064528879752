.section {
  border-radius: 4px;
  padding: 20px 28px;
}

@media screen and (max-width: 1036px) {
  .section {
    border-radius: 0;
  }

  .square {
    overflow: scroll;
  }
}

.labo {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.laboTitle {
  position: relative;

  &:after {
    content: "";
    width: 61px;
    height: 52px;
    background: url("https://asset.basefood.co.jp/images/parts/illust_meals.png") no-repeat center
      center;
    background-size: contain;
    position: absolute;
    right: 5px;
    top: 0;
  }
}

.squareWrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.list {
  width: min-content;

  &:last-child:not(:nth-child(2)) {
    padding-right: 28px;
  }

  &:last-child:nth-child(2) {
    margin-right: 28px;
  }
}

.illustLabo {
  display: block;
  width: calc(100% + 56px);
  margin: 28px -28px -24px;
}
