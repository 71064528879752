.container {
  padding: 20px 28px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

@media screen and (max-width: 1036px) {
  .container {
    border-radius: 0;
  }
}

.point {
  display: flex;
  align-items: center;
}

.title {
  display: flex;
  margin-left: 10px;
  gap: 5px;
}

.toggle {
  margin-left: auto;
  cursor: pointer;
}

.toggle i {
  margin-left: 10px;
}

.inviteLink {
  font-size: 12px;
  border-bottom: 1px solid;
  margin-left: auto;
}

.expiry {
  border-radius: 4px;
  padding: 10px;
  position: relative;
}

.expiryItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.angle {
  position: absolute;
  right: 60px;
  top: -9px;
}

.exclamation {
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -3px;
}

.tooltipIcon {
  color: #c8c8c8;
}
