@import "../../../../../../style/rankColor.variables.scss";

.panelTable {
  display: table;
  width: calc(100% + 10px);
  margin-left: -5px;
}

.panelCell {
  display: table-cell;
  width: 50%;
  padding: 0 5px;
}

.panel {
  cursor: pointer;
  padding: 20px;
  border-radius: 10px;
  height: 100%;
}

.largeNumber {
  margin-right: 5px;
}

.panelDetail {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.panelDetail:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  position: absolute;
  top: -9px;
}

.detailRight:before {
  right: calc(25% - 10px);
}

.detailLeft:before {
  left: calc(25% - 10px);
}

.panelIcon {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  padding-top: 8px;
  margin-right: 10px;
}

.panelQuestion {
  margin-left: auto;
  padding-left: 10px;
  cursor: pointer;
}

.panelCongrats {
  margin-left: 10px;
  margin-bottom: -10px;
}

.Economy {
  &PanelOn {
    border: 2px solid $economy-primary;
  }
  &Background {
    background-color: $economy-secondary-bg;
  }
  &Background:before {
    border-color: transparent transparent $economy-secondary-bg transparent;
  }
}

.Business {
  &PanelOn {
    border: 2px solid $business-primary;
  }
  &Background {
    background-color: $business-secondary-bg;
  }
  &Background:before {
    border-color: transparent transparent $business-secondary-bg transparent;
  }
}

.First {
  &PanelOn {
    border: 2px solid $first-primary;
  }
  &Background {
    background-color: $first-secondary-bg;
  }
  &Background:before {
    border-color: transparent transparent $first-secondary-bg transparent;
  }
}

.Vip {
  &PanelOn {
    border: 2px solid $vip-primary;
  }
  &Background {
    background-color: $vip-secondary-bg;
  }
  &Background:before {
    border-color: transparent transparent $vip-secondary-bg transparent;
  }
}
