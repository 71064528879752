.section {
  border-radius: 4px;
  padding: 20px 28px;
}

@media screen and (max-width: 1036px) {
  .section {
    border-radius: 0;
  }
}

.deliverySchedule {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-bottom: 10px;
  position: relative;

  &:after {
    content: "";
    width: 90px;
    height: 130px;
    background: url("https://asset.basefood.co.jp/images/parts/illust_delivery_man_cut.png")
      no-repeat center center;
    background-size: contain;
    position: absolute;
    right: 35px;
    top: -45px;
    z-index: 1;
  }
}

.subscription {
  display: flex;
  flex-flow: column;
}

.subscriptionRelative {
  position: relative;
}

.subscriptionDisabled {
  pointer-events: none;
  opacity: 0.5;
}
