.listItem {
  width: 100%;
  height: 100%;
}

.link {
  display: block;
  transition: all 0.3s ease;
  width: 100%;
}

.imageContainer {
  display: block;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1036px) {
  .imageContainer {
    min-width: 154px;
  }
}

.image {
  border-radius: 4px;
}

.button {
  cursor: pointer;
}
