@use "../../../../../style/rankColor.variables.scss" as rankColor;

.rankContainer {
  padding: 20px 28px;
}

.background {
  &Economy {
    background-color: rankColor.$economy-primary-bg;
  }
  &Business {
    background-color: rankColor.$business-primary-bg;
  }
  &First {
    background-color: rankColor.$first-primary-bg;
  }
  &Vip {
    background-color: rankColor.$vip-primary-bg;
  }
}

@media screen and (max-width: 1036px) {
  .container {
    border-radius: 0;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.rank {
  width: 100%;
}

.currentRank {
  align-items: self-start;
}

.rankImage {
  margin-right: 20px;
}

.progressBarContainer {
  height: 10px;
}

.rankName {
  padding-top: 3px;
  display: table-cell;
  vertical-align: top;
  width: calc(100% - 80px);
  padding-left: 40px;
}

.rankDetailLink {
  border-radius: 12px;
  display: flex;
  padding: 3px 10px;
}

.present {
  padding-top: 3px;
  margin-left: 5px;
}
