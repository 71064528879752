.subscriptionDate {
  position: relative;
  z-index: 10;
}

.subscriptionLink {
  display: block;
  border-radius: 4px;
  padding: 20px;
  position: relative;
}

.productsContainer {
  margin-right: -20px;
  margin-left: -20px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.products {
  display: flex;
  flex-wrap: nowrap;
  margin-left: 20px;
}

.product {
  position: relative;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
    padding-right: 20px;

    // TODO: ロジックいれる際に修正
    span {
      right: 12px;
    }
  }
}

.productCount {
  position: absolute;
  right: -8px;
  bottom: 0;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  white-space: nowrap;
  background-color: #5a5a5a;
  line-height: 24px;
}

.subscriptionTag {
  vertical-align: bottom !important;
  margin-left: 0 !important;
  margin-right: 10px !important;
}

.revocableOrder {
  margin: 0 -20px -20px;
  padding: 20px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.orderDetailLink {
  border-bottom: 1px solid #5a5a5a;
}

.cautionsLink {
  border-bottom: 1px solid;
}
