.link {
  display: block;
  transition: all 0.3s ease;
  width: 100%;
}

.imageContainer {
  display: block;
  width: 100%;
  height: auto;
}

.image {
  border-radius: 4px;
}
